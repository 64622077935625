import { Grid } from '@mui/material';
import { useLocation } from 'react-router';
import Logo from '../../../../assets/images/Logo.jpg';
import { adjustTimePlus } from '../../../../utils/timeUtils';

function PrintReceipt() {
  const { state } = useLocation();
  const { detailOrder, listLaundryItem } = state;
  const businessInformation = JSON.parse(sessionStorage.getItem('business_information'));

  return (
    <>
      <div style={{ padding: 10 }}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <h3 style={{ textAlign: 'center' }}>Daftar Barang</h3>

            <br />
            {listLaundryItem.map((item, index) => (
              <div style={{ fontSize: '14px' }}>
                <Grid container spacing={1}>
                  <Grid item>{index + 1}.</Grid>
                  <Grid item>
                    <div>
                      {item.namaBarang} ({item.jenisLaundry})
                    </div>
                    <div>
                      {item.kuantitas}x{' '}
                      {new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(item.harga)}
                    </div>
                  </Grid>
                </Grid>
              </div>
            ))}
          </Grid>

          <Grid item xs={6}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
              <img src={Logo} width={120} alt="Logo" />
              <h4>{businessInformation.slogan}</h4>
              <div style={{ maxWidth: '420px', fontSize: '14px' }}>{businessInformation.lokasi}</div>
              <div>
                <strong>#{detailOrder.nomorPesanan}</strong>
              </div>
            </div>
            <br />
            <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <strong>Nama Pelanggan :</strong>
                <span style={{ textAlign: 'end' }}>{detailOrder.User.nama}</span>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <strong>Tanggal Masuk :</strong>
                <span style={{ textAlign: 'end' }}>{` ${detailOrder.tglMulai.slice(8, 10)}/${detailOrder.tglMulai.slice(
                  5,
                  7
                )}/${detailOrder.tglMulai.slice(0, 4)} ${(
                  '0' + adjustTimePlus(parseInt(detailOrder.tglMulai.slice(11, 13)))
                ).slice(-2)}:${detailOrder.tglMulai.slice(14, 16)}`}</span>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <strong>Estimasi Selesai :</strong>
                <span style={{ textAlign: 'end' }}>{` ${detailOrder.tenggatWaktu.slice(
                  8,
                  10
                )}/${detailOrder.tenggatWaktu.slice(5, 7)}/${detailOrder.tenggatWaktu.slice(0, 4)} ${(
                  '0' + adjustTimePlus(parseInt(detailOrder.tenggatWaktu.slice(11, 13)))
                ).slice(-2)}:${detailOrder.tenggatWaktu.slice(14, 16)}`}</span>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <strong>Total Pembayaran :</strong>
                <span style={{ textAlign: 'end' }}>
                  {new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(
                    detailOrder.totalHarga
                  )}{' '}
                  ({detailOrder.statusPembayaran})
                </span>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <strong>Metode Pembayaran :</strong>
                <span style={{ textAlign: 'end' }}>{detailOrder.mPembayaran}</span>
              </div>
            </div>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <strong>Alamat Penjemputan</strong>

                <div style={{ fontSize: '14px' }}>
                  {!detailOrder.alamatJemput ? (
                    '-'
                  ) : (
                    <>
                      {detailOrder.alamatJemput.kecamatan ? `Kecamatan ${detailOrder.alamatJemput.kecamatan}` : null}
                      {detailOrder.alamatJemput.kelurahan ? `, Kelurahan ${detailOrder.alamatJemput.kelurahan}` : null}
                      {detailOrder.alamatJemput.rw ? `, RW ${detailOrder.alamatJemput.rw}` : null}
                      {detailOrder.alamatJemput.rt ? `, RT ${detailOrder.alamatJemput.rt}` : null}
                      {detailOrder.alamatJemput.kategori ? `, ${detailOrder.alamatJemput.kategori}` : null}
                      {detailOrder.alamatJemput.detail ? ` ${detailOrder.alamatJemput.detail}` : null}
                      {detailOrder.alamatJemput.deskripsi ? `, ${detailOrder.alamatJemput.deskripsi}` : null}
                    </>
                  )}
                </div>
              </Grid>
              <Grid item xs={6}>
                <strong>Alamat Pengantaran</strong>

                <div style={{ fontSize: '14px' }}>
                  {!detailOrder.alamatAntar ? (
                    '-'
                  ) : (
                    <>
                      {detailOrder.alamatAntar.kecamatan ? `Kecamatan ${detailOrder.alamatAntar.kecamatan}` : null}
                      {detailOrder.alamatAntar.kelurahan ? `, Kelurahan ${detailOrder.alamatAntar.kelurahan}` : null}
                      {detailOrder.alamatAntar.rw ? `, RW ${detailOrder.alamatAntar.rw}` : null}
                      {detailOrder.alamatAntar.rt ? `, RT ${detailOrder.alamatAntar.rt}` : null}
                      {detailOrder.alamatAntar.kategori ? `, ${detailOrder.alamatAntar.kategori}` : null}
                      {detailOrder.alamatAntar.detail ? ` ${detailOrder.alamatAntar.detail}` : null}
                      {detailOrder.alamatAntar.deskripsi ? `, ${detailOrder.alamatAntar.deskripsi}` : null}
                    </>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default PrintReceipt;
