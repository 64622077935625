export const banyumasAreaList = [{
        subDistrict: 'Daerah Lainnya',
        urbanVillage: ['Luar Banyumas', 'Dalam Banyumas'
        ]
    }, {
        subDistrict: 'Ajibarang',
        urbanVillage: ['Ajibarang Kulon',
            'Ajibarang Wetan',
            'Banjarsari',
            'Ciberung',
            'Darmakradenan',
            'Jingkang',
            'Kalibenda',
            'Karangbawang',
            'Kracak',
            'Lesmana',
            'Pancasan',
            'Pancurendang',
            'Pandansari',
            'Sawangan',
            'Tipar Kidul',
        ]
    },
    {
        subDistrict: 'Banyumas',
        urbanVillage: ['Binangun',
            'Danaraja',
            'Dawuhan',
            'Kalisube',
            'Karangrau',
            'Kedunggede',
            'Kedunguter',
            'Kejawar',
            'Papringan',
            'Pasinggangan',
            'Pekunden',
            'Sudagaran',
        ]
    },
    {
        subDistrict: 'Baturaden',
        urbanVillage: ['Karang Tengah',
            'Karangmangu',
            'Karangsalam Lor',
            'Kebumen',
            'Kemutug Kidul',
            'Kemutug Lor',
            'Ketenger',
            'Kutasari',
            'Pamijen',
            'Pandak',
            'Purwosari',
            'Rempoah',
        ]
    },
    {
        subDistrict: 'Cilongok',
        urbanVillage: ['Batuanten',
            'Cikidang',
            'Cilongok',
            'Cipete',
            'Gununglurah',
            'Jatisaba',
            'Kalisari',
            'Karanglo',
            'Karangtengah',
            'Kasegeran',
            'Langgongsari',
            'Pageraji',
            'Panembangan',
            'Panusupan',
            'Pejogol',
            'Pernasidi',
            'Rancamaya',
            'Sambirata',
            'Sokawera',
            'Sudimara',
        ]
    },
    {
        subDistrict: 'Gumelar',
        urbanVillage: ['Cihonje',
            'Cilangkap',
            'Gancang',
            'Gumelar',
            'Karangkemojing',
            'Kedungurang',
            'Paningkaban',
            'Samudra',
            'Samudra Kulon',
            'Tlaga',
        ]
    },
    {
        subDistrict: 'Kalibagor',
        urbanVillage: ['Kalibagor',
            'Kalicupak Kidul',
            'Kalicupak Lor',
            'Kaliori',
            'Kalisogra Wetan',
            'Karangdadap',
            'Pajerukan',
            'Pekaja',
            'Petir',
            'Srowot',
            'Suro',
            'Wlahar Wetan',
        ]
    },
    {
        subDistrict: 'Karanglewas',
        urbanVillage: ['Babakan',
            'Jipang',
            'Karanggude Kulon',
            'Karangkemiri',
            'Karanglewas Kidul',
            'Kediri',
            'Pangebatan',
            'Pasir Kulon',
            'Pasir Lor',
            'Pasir Wetan',
            'Singasari',
            'Sunyalangu',
            'Tamansari',
        ]
    },
    {
        subDistrict: 'Kebasen',
        urbanVillage: ['Adisana',
            'Bangsa',
            'Cindaga',
            'Gambarsari',
            'Kalisalak',
            'Kaliwedi',
            'Karangsari',
            'Kebasen',
            'Mandirancan',
            'Randegan',
            'Sawangan',
            'Tumiyang',
        ]
    },
    {
        subDistrict: 'Kedung Banteng',
        urbanVillage: ['Baseh',
            'Beji',
            'Dawuhan Kulon',
            'Dawuhan Wetan',
            'Kalikesur',
            'Kalisalak',
            'Karangnangka',
            'Karangsalam Kidul',
            'Kebocoran',
            'Kedung Banteng',
            'Keniten',
            'Kutaliman',
            'Melung',
            'Windujaya',
        ]
    },
    {
        subDistrict: 'Kembaran',
        urbanVillage: ['Bantarwuni',
            'Bojongsari',
            'Dukuhwaluh',
            'Karangsari',
            'Karangsoka',
            'Karangtengah',
            'Kembaran',
            'Kramat',
            'Ledug',
            'Linggasari',
            'Pliken',
            'Purbadana',
            'Purwodadi',
            'Sambeng Kulon',
            'Sambeng Wetan',
            'Tambaksari Kidul',
        ]
    },
    {
        subDistrict: 'Kemranjen',
        urbanVillage: ['Alasmalang',
            'Grujugan',
            'Karanggintung',
            'Karangjati',
            'Karangsalam',
            'Kebarongan',
            'Kecila',
            'Kedungpring',
            'Nusamangir',
            'Pageralang',
            'Petarangan',
            'Sibalung',
            'Sibrama',
            'Sidamulya',
            'Sirau',
        ]
    },
    {
        subDistrict: 'Jatilawang',
        urbanVillage: ['Adisara',
            'Bantar',
            'Gentawangi',
            'Gunung Wetan',
            'Karanganyar',
            'Karanglewas',
            'Kedungwringin',
            'Margasana',
            'Pekuncen',
            'Tinggarjaya',
            'Tunjung',
        ]
    },
    {
        subDistrict: 'Lumbir',
        urbanVillage: ['Besuki',
            'Canduk',
            'Cidora',
            'Cingebul',
            'Cirahab',
            'Dermaji',
            'Karanggayam',
            'Kedunggede',
            'Lumbir',
            'Parungkamal',
        ]
    },
    {
        subDistrict: 'Patikraja',
        urbanVillage: ['Karanganyar',
            'Karangendep',
            'Kedungrandu',
            'Kedungwringin',
            'Kedungwuluh Kidul',
            'Kedungwuluh Lor',
            'Notog',
            'Patikraja',
            'Pegalongan',
            'Sawangan Wetan',
            'Sidabowa',
            'Sokawera Kidul',
            'Wlahar Kulon',
        ]
    },
    {
        subDistrict: 'Pekuncen',
        urbanVillage: ['Banjaranyar',
            'Candinegara',
            'Cibangkong',
            'Cikawung',
            'Cikembulan',
            'Glempang',
            'Karangkemiri',
            'Karangklesem',
            'Krajan',
            'Kranggan',
            'Pasiraman Kidul',
            'Pasiraman Lor',
            'Pekuncen',
            'Petahunan',
            'Semedo',
            'Tumiyang',
        ]
    },
    {
        subDistrict: 'Purwojati',
        urbanVillage: ['Gerduren',
            'Kaliputih',
            'Kalitapen',
            'Kaliurip',
            'Kaliwangi',
            'Karangmangu',
            'Karangtalun Kidul',
            'Karangtalun Lor',
            'Klapasawit',
            'Purwojati',
        ]
    },
    {
        subDistrict: 'Purwokerto Barat',
        urbanVillage: ['Bantarsoka',
            'Karanglewas Lor',
            'Kedungwuluh',
            'Kober',
            'Pasir Kidul',
            'Pasirmuncang',
            'Rejasari',
        ]
    },
    {
        subDistrict: 'Purwokerto Selatan',
        urbanVillage: ['Berkoh',
            'Karangklesem',
            'Karangpucung',
            'Purwokerto Kidul',
            'Purwokerto Kulon',
            'Tanjung',
            'Teluk',
        ]
    },
    {
        subDistrict: 'Purwokerto Timur',
        urbanVillage: ['Arcawinangun',
            'Kranji',
            'Mersi',
            'Purwokerto Lor',
            'Purwokerto Wetan',
            'Sokanegara',
        ]
    },
    {
        subDistrict: 'Purwokerto Utara',
        urbanVillage: ['Bancarkembar',
            'Bobosan',
            'Grendeng',
            'Karangwangkal',
            'Pabuaran',
            'Purwanegara',
            'Sumampir',
        ]
    },
    {
        subDistrict: 'Rawalo',
        urbanVillage: ['Banjarparakan',
            'Losari',
            'Menganti',
            'Pesawahan',
            'Rawalo',
            'Sanggreman',
            'Sidamulih',
            'Tambaknegara',
            'Tipar',
        ]
    },
    {
        subDistrict: 'Sokaraja',
        urbanVillage: ['Banjaranyar',
            'Banjarsari Kidul',
            'Jompo Kulon',
            'Kalikidang',
            'Karangduren',
            'Karangkedawung',
            'Karangnanas',
            'Karangrau',
            'Kedondong',
            'Klahang',
            'Lemberang',
            'Pamijen',
            'Sokaraja Kidul',
            'Sokaraja Kulon',
            'Sokaraja Lor',
            'Sokaraja Tengah',
            'Sokaraja Wetan',
            'Wiradadi',
        ]
    },
    {
        subDistrict: 'Somagede',
        urbanVillage: ['Kanding',
            'Kemawi',
            'Klinting',
            'Piasa Kulon',
            'Plana',
            'Sokawera',
            'Somagede',
            'Somakaton',
            'Tanggeran',
        ]
    },
    {
        subDistrict: 'Sumbang',
        urbanVillage: ['Banjarsari Kulon',
            'Banjarsari Wetan',
            'Banteran',
            'Ciberem',
            'Datar',
            'Gandatapa',
            'Karangcegak',
            'Karanggintung',
            'Karangturi',
            'Kawungcarang',
            'Kebanggan',
            'Kedungmalang',
            'Kotayasa',
            'Limpakuwus',
            'Sikapat',
            'Silado',
            'Sumbang',
            'Susukan',
            'Tambaksogra',
        ]
    },
    {
        subDistrict: 'Sumpiuh',
        urbanVillage: ['Banjarpanepen',
            'Bogangin',
            'Karanggedang',
            'Kemiri',
            'Ketanda',
            'Kuntili',
            'Lebeng',
            'Nusadadi',
            'Pandak',
            'Selandaka',
            'Selanegara',
            'Kebokura',
            'Kradenan',
            'Sumpiuh',
        ]
    },
    {
        subDistrict: 'Tambak',
        urbanVillage: ['Karangpetir',
            'Karangpucung',
            'Kamulyan',
            'Gebangsari',
            'Pesantren',
            'Prembun',
            'Buniayu',
            'Purwodadi',
            'Watuagung',
            'Gumelar Lor',
            'Gumelar Kidul',
            'Plangkapan',
        ]
    },
    {
        subDistrict: 'Wangon',
        urbanVillage: ['Banteran',
            'Cikakak',
            'Jambu',
            'Jurangbahas',
            'Klapagading',
            'Klapagading Kulon',
            'Pangadegan',
            'Randegan',
            'Rawaheng',
            'Wangon',
            'Windunegara',
            'Wlahar',
        ]
    },
]